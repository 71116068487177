import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reader"
export default class extends Controller {
  get title() {
    return document.getElementById('title');
  }

  get title_html() {
    return document.querySelector('div[data-controller*="toc"]');
  }

  connect() {
    this.hide();
  }

  continue() {
    if(!!this.title) {
      this.title.style.display = 'initial';
      return this.title.scrollIntoView(true);
    }
    if(!!this.title_html)
      this.title_html.style = null;
      this.title_html.scrollIntoView(true);
  }

  show() {

  }

  hide() {
    if(!!this.title && !window.location.hash)
      this.title.style.display = 'none';
    if(!!this.title_html) {
      this.title_html.style.display = 'none';
    }
  }
}
