import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="jump"
export default class extends Controller {
  static values = {
    base: String,
    blank: String
  }

  to() {
    let base = this.baseValue;
    let blank = this.blankValue;
    if (this.element.value) {
      Turbo.visit(base.replace('ID', this.element.value).replace(/\/$/, ''));
    } else {
      if (!!blank) Turbo.visit(blank);
    }
  }
}
