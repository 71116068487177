import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="letternav"
export default class extends Controller {
  static targets = ['letterframe', 'loading'];

  load(evt) {
    const tgt = evt.currentTarget;
    const output = this.letterframeTarget;

    this.mark_current(tgt);
    this.clear_search();
    output.innerHTML = '';
    output.appendChild(this.loadingTarget.content.cloneNode(true));
    fetch(tgt.href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      }
    })
    .then((r) => r.text())
    .then(html => Turbo.renderStreamMessage(html));
  }

  mark_current(tgt) {
    for(const child of tgt.closest('ul').children) {
      child.classList.remove('current');
    }
    tgt.closest('li').classList.add('current');
  }

  clear_search() {
    const search = document.getElementById('p');
    if (search) search.value = '';
  }
}
