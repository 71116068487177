import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reference"
export default class extends Controller {
  connect() {
    console.log('for your reference');
  }

  show(evt) {
    let elm = evt.target;
    if (!elm.matches('p[id], ul[id], ol[id]')) return false;

    if (!elm.ref_button) {
      elm.ref_button = document.createElement('button');
      elm.ref_button.classList.add('reference-button', 'btn', 'btn-sm', 'btn-outline-secondary');
      elm.ref_button.setAttribute('data-action', 'click->reference#copy');
      elm.ref_button.setAttribute('data-reference-anchor-param', `#${elm.id}`);
      elm.ref_button.innerHTML = 'Copy Reference <span class="b-fa fa fa-external-link"></span>';
      elm.prepend(elm.ref_button);
    }

    elm.ref_button.classList.toggle('shown');
  }

  hide(evt) {
    let elm = evt.target;
    if (!elm.ref_button) return false;

    elm.ref_button.classList.toggle('shown');
  }

  copy(evt) {
    var url = window.location.href;
    url = url.split('#')[0].split('?')[0];
    url += evt.params['anchor'];
    this.clippy(url, evt.target);
  }

  clippy(text, elm) {
    navigator.clipboard
             .writeText(text)
             .then(() => this.copied(elm));
  }

  copied(elm) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    elm.classList.add('confirm');

    this.timeout = setTimeout(() => {
      elm.classList.remove('confirm');
    }, 500);
  }
}
