import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="page-view"
export default class extends Controller {
  static targets = ['reader', 'slider'];

  connect() {
  }

  setup(evt) {
    // document.querySelector('body').classList.toggle('stuck');
    this.readerTarget.classList.toggle('reading');
    evt.target.classList.toggle('active');
    this.readerTarget.scrollIntoView();
    this.manage_controls();
    this.element.classList.toggle('darken');
  }

  previous(evt) {
    const reader = this.readerTarget;
    const slider = this.sliderTarget;
    const offset = reader.clientHeight - 20;
    const scroll = reader.scrollTop;
    const move = Math.round(scroll - offset);
    slider.classList.add('fade');
    if (move < 0) {
      reader.scrollTo(0, 0);
    } else {
      reader.scrollTo(0, (move - (move % 40)));
    }
    setTimeout(function() {
      slider.classList.remove('fade');
    }, 200);
  }

  next(evt) {
    const reader = this.readerTarget;
    const slider = this.sliderTarget;
    const offset = reader.clientHeight - 20;
    const scroll = reader.scrollTop;
    const move = Math.round(scroll + offset);
    slider.classList.add('fade');
    if (move > slider.clientHeight) {
      reader.scrollTo(0, slider.clientHeight - offset);
    } else {
      reader.scrollTo(0, (move - (move % 40)));
    }
    setTimeout(function() {
      slider.classList.remove('fade');
    }, 200);
  }

  manage_controls() {
    const reader = this.readerTarget;
    if(!reader.querySelector('.paging')) {
      reader.insertAdjacentHTML('afterbegin', '<button class="previous-page paging btn btn-outline-secondary btn-lg" data-action="click->page-view#previous" rel="nav"><i class="fa fa-chevron-left"></i></button>');
      reader.insertAdjacentHTML('beforeend', '<button class="next-page paging btn btn-outline-secondary btn-lg" data-action="click->page-view#next" rel="nav"><i class="fa fa-chevron-right"></i></button>');
    }
    reader.scrollTo(0, 0);
  }

}
