import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use";

// Connects to data-controller="alpha-scrollspy"
export default class extends Controller {
  static targets = ["item", "header"]

  connect() {
    const header_height = `${this.headerTarget.offsetHeight}px`;
    this.itemTargets.forEach((elm) => {
      useIntersection(this, { element: elm, rootMargin: `-${header_height} 0 0`, dispatchEvent: false });
      elm.style.scrollMarginTop = header_height;
    });
  }

  disconnect() {
    this.itemTargets.forEach(elm => {
      elm.style.scrollMarginTop = null;
    })
  }

  appear(entry) {
    const letter = this.headerTarget.querySelector(`a[href*='${entry.target.id}']`);
    if (letter) {
      letter.parentElement.classList.add('active')
    }
  }

  disappear(entry) {
    const letter = this.headerTarget.querySelector(`a[href*='${entry.target.id}']`);
    if (letter) {
      letter.parentElement.classList.remove('active')
    }
  }
}
