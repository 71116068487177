import { ApplicationController, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static debounces = ['seek']
  static targets = ['form', 'loading', 'results', 'reset', 'qs'];

  connect() {
    useDebounce(this)
  }

  seek(evt) {
    this.clear_current();
    if (! this.resultsTarget.querySelector('.loading-indicator')) {
      this.clear_results();
      this.resultsTarget.appendChild(this.loadingTarget.content.cloneNode(true));
    }
    if (this.hasQsTarget) this.qsTarget.value = evt.target.value;
    this.formTarget.requestSubmit();
  }

  clear_results() {
    this.resultsTarget.innerHTML = '';
  }

  clear_current() {
    if (!document.querySelector('ul.letter-blocks')) return;

    for(const child of document.querySelector('ul.letter-blocks').children) {
      child.classList.remove('current');
    }
  }
}
