import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ['slider', 'slide', 'dot', 'arrow'];

  get slider() {
    return this.sliderTarget;
  }

  get dots() {
    return this.dotTargets;
  }

  connect() {
    console.log('connecting to carousel');
    this.activate_dot();
    this.activate_arrows();

    this.element.style.setProperty('--total', this.slideTargets.length);
    this.slider.addEventListener('wheel', evt => {
      if (this.slider.scrollLeft > -1) {
        this.activate_dot();
        this.activate_arrows();
      }
    });
  }

  dotNavigate(evt) {
    evt.preventDefault();

    let elm = evt.currentTarget;
    let tgt = this.element.querySelector(elm.hash);
    if (elm && tgt) {
      this.activate_dot(elm);
      let previous_count = this.dots.findIndex(function(el) {
        return (el === elm)
      });
      let left = Math.ceil((this.slider.clientWidth + 16) * previous_count);
      this.slider.scrollTo(left, 0);
    }
  }

  activate_dot(dot = false) {
    if(!this.dots.length) return;

    this.dots.forEach(function(elm) {
      elm.classList.remove('active');
    });

    if (dot) {
      dot.classList.add('active');
    } else {
      let index = Math.round(this.slider.scrollLeft / this.slider.clientWidth);
      this.dots[index].classList.add('active');
    }
  }

  activate_arrows() {
    const arrows = this.arrowTargets;
    arrows.forEach(elm => { elm.classList.add('disabled') });
    let remainder = this.slider.scrollWidth - this.slider.scrollLeft - this.slider.clientWidth;
    if (remainder > 0) {
      arrows[1].classList.remove('disabled');
    }
    if (this.slider.scrollLeft > 0) {
      arrows[0].classList.remove('disabled');
    }
  }

  next(evt) {
    let left = this.slider.scrollLeft + this.slider.clientWidth + 15;
    this.slider.scrollTo(left, 0);
    setTimeout(() => {
      this.activate_arrows();
    }, 500);
  }

  previous(evt) {
    let left = this.slider.scrollLeft - this.slider.clientWidth - 15;
    this.slider.scrollTo(left, 0);
    setTimeout(() => {
      this.activate_arrows();
    }, 500);
  }
}
