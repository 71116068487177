import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="more"
export default class extends Controller {
  static values = {
    url: String,
    scrub: Array,
    replace: Array,
    target: String
  }

  link(evt) {
    const tgt = (this.hasTargetValue ? this.targetValue : '_top');
    window.open(this.urlValue, tgt);
  }

  get_more(evt) {
    let url = this.urlValue + '?' + new URLSearchParams({
      scrub: this.scrubValue,
      replace: this.replaceValue
    });
    evt.preventDefault();
    evt.stopPropagation();
    evt.stopImmediatePropagation();

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
