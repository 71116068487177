// Entry point for the build script in your package.json
import './controllers';
import ContentLoader from 'stimulus-content-loader';
import { application } from './controllers/application';
application.register('content-loader', ContentLoader);
import './channels';
import * as bootstrap from 'bootstrap';
import '@hotwired/turbo-rails';
import '@justinribeiro/lite-youtube';
import LocalTime from 'local-time';
LocalTime.start();
