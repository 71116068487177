import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useIntersection(this)
    this.stickies = document.querySelectorAll('.sticky-top')
    this.stickies.forEach((elm) => { elm.classList.add('hiding', 'is-pinned') });
  }

  appear(entry, observer) {
    // callback automatically triggered when the element
    // intersects with the viewport (or root Element specified in the options)
    this.stickies.forEach((elm) => { elm.classList.remove('is-pinned') });
  }

  disappear(entry, observer) {
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
    this.stickies.forEach((elm) => { elm.classList.add('is-pinned') });
  }
}
// data-action="appear@window->counter#increase disappear@window->counter#decrease"
