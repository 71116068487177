import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="missing"
export default class extends Controller {
  get holder() {
    return this.element.querySelector('.warning-holder');
  }

  connect() {
    if (!document.querySelector('.missing')) return;

    if (!this.holder) {
      this.element.insertAdjacentHTML('afterbegin', '<div class="warning-holder"></div>');
    }
    console.log(this.holder)

    this.holder.insertAdjacentHTML('afterbegin', '<button class="btn btn-light mx-2 missing-toggle open" data-action="missing#flip">Missing</button>');
  }

  flip() {
    this.element.classList.toggle('neeson');
  }
}
