import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toc"
export default class extends Controller {
  static targets = [ 'toc' ];

  toggle(evt) {
    console.log(this.tocTarget)
    evt.target.classList.toggle('active');
    this.tocTarget.classList.toggle('shown')
  }
}
