import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="letter"
export default class extends Controller {
  choose(evt) {
    var tgt = evt.currentTarget;
    var letter = tgt.search.split('letter=')[1]

    var frame = document.getElementById(letter);
    setTimeout(() => {
      frame.scrollIntoView();
    }, 50);
  }
}
