import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="footnote"
export default class extends Controller {
  static targets = [ 'popper' ];

  connect(){
    this.element.setAttribute('data-turbo', false);
  }

  display(evt) {
    evt.preventDefault();
    const dialog = this.popperTarget;
    let elm = evt.currentTarget;
    let tgt = document.getElementById(elm.getAttribute('data-src'));
    if (tgt.tagName == 'A') {
      tgt = tgt.closest('p, div');
    }
    dialog.innerHTML = tgt.innerHTML;
    dialog.showModal();
  }

  hide(evt) {
    const dialog = this.popperTarget;
    if (dialog.open)
      dialog.close();
  }
}
