import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  connect() {
    console.log('📎 It looks like you’re reading something. Would you like me to help you with that?')
  }

  copy(evt) {
    const content = evt.params['content'];
    this.success = evt.params['success'];
    this.button = evt.target;
    this.original = this.button.innerHTML;
    this.extract(content);
  }

  extract(content) {
    if (content === 'location') {
      this.clippy(window.location.href);
    } else if (!!content) {
      let host = document.querySelector(content);
      let credits = document.querySelector('title');
      if (!!host) {
        var text = host.innerText;
        if (!!credits) text += `${credits.innerText}`;
        text += `\n${window.location.href}`
        this.clippy(text);
      } else {
        console.error('Couldn’t find the source');
        return false;
      }
    } else {
      console.error('Configuration error, probably.');
      return false;
    }
  }

  clippy(text) {
    navigator.clipboard
             .writeText(text)
             .then(() => this.copied());
  }

  copied() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.button.innerHTML = this.success;
    this.button.classList.add('confirm');

    this.timeout = setTimeout(() => {
      this.button.innerHTML = this.original;
      this.button.classList.remove('confirm');
    }, 2000);
  }
}
