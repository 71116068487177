import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expando"
export default class extends Controller {
  static targets = ['button', 'item'];

  connect() {
    const items = this.itemTargets;
    const anchor = window.location.hash;
    if (anchor) {
      const target_item = items.find((elm) => { return elm.matches(anchor) });
      if (target_item) {
        target_item.open = true;
        target_item.scrollIntoView();
      }
    } else {
      if (items[0]) items[0].open = true;
    }

    this.buttonTarget.addEventListener('click', (evt) => {
      var expanded = this.expanded();
      Array.from(items).forEach(function(elm){
        elm['open'] = !expanded;
      });
      this.caption();
    });

    this.element.addEventListener('click', (evt) => {
      if (!evt.target.closest('details')) return;

      setTimeout(() => { this.caption(); }, 5);
    });

    this.caption();
  };

  expanded() {
    const items = this.itemTargets;
    return Array.from(items).filter((elm) => { return elm.open == true }).length == items.length;
  };

  caption() {
    this.buttonTarget.innerText = this.expanded() ? 'Collapse All' : 'Expand All';
  };
}
