import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hover"
export default class extends Controller {
  connect() {
    if (window.matchMedia("(pointer: coarse)").matches) {
      this.bifurcate_option(this.element);
      return;
    }

    this.element.setAttribute('data-action', "mouseenter->hover#expand mouseleave->hover#collapse");
  }

  expand(evt) {
    this.toggle_shown();
    this.element.querySelector('[aria-expanded]').setAttribute('aria-expanded', true);
  }

  collapse(evt) {
    this.toggle_shown();
    this.element.querySelector('[aria-expanded]').setAttribute('aria-expanded', false);
  }

  toggle_shown() {
    this.element.classList.toggle('show');
    this.element.querySelector('.dropdown-menu').classList.toggle('show');
  }

  bifurcate_option(elm) {
    const menu = elm.querySelector('ul')
    const link = elm.querySelector('a');
    link.removeAttribute('aria-expanded');
    link.classList.remove('dropdown-toggle');
    link.classList.add('mobile');
    link.insertAdjacentHTML('afterend', `<button class="btn link-dropdown dropdown-toggle mobile dropdown-toggle-split" data-bs-reference="parent" data-bs-toggle="dropdown" aria-expanded="false"></button>`)
  }
}
