import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="themes"
export default class extends Controller {
  static targets = ['picker', 'card'];

  connect() {
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }

    var available = [];

    this.cardTargets.forEach(function(elm) {
      const themes = JSON.parse(elm.dataset['themes']);
      available.push(themes)
    });

    available = available.flat().filter(onlyUnique);

    Array.from(this.pickerTarget.options).forEach(function(opt) {
      if (opt.value && !available.includes(parseInt(opt.value, 10))) {
        opt.remove();
      }
    })
  }

  filter(evt) {
    const selection = evt.target.value;
    evt.target.blur();
    if (!selection) {
      this.cardTargets.forEach(function(elm) {
        elm.classList.remove('hidden');
      });
      return;
    }

    this.cardTargets.forEach(function(elm) {
      if(elm.getAttribute('data-themes').includes(selection)) {
        elm.classList.remove('hidden');
      } else {
        elm.classList.add('hidden');
      }
    });
  }
}
