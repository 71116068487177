import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="anchor"
export default class extends Controller {
  deep_link(tgt = null) {
    if(typeof(tgt) === 'object') { tgt = null; }
    const anchor = tgt || window.location.hash.toString().split('#')[1];
    if (!anchor) { return false };

    this.continue();

    let elm = document.getElementById(anchor);
    if (!elm) {
      console.log(`Could not locate ${anchor} in the page`)
      return false
    };

    let top = (elm.getBoundingClientRect()['top']
               + window.pageYOffset
               - document.documentElement.clientTop);

    window.scrollTo({top: top - 80});
    elm.classList.add('highlight');
    return true;
  }

  present(evt) {
    const anchor = evt.target.href.toString().split('#')[1];
    if (anchor) {
      if (this.continue()) {
        this.deep_link(anchor);
      }
    }
  }

  get title() {
    return document.getElementById('title');
  }

  get title_html() {
    return document.getElementById('title-html');
  }

  continue() {
    if(!!this.title) {
      this.title.style.display = 'initial';
      this.title.scrollIntoView(true);
    }
    if(!!this.title_html) {
      this.title_html.style.display = 'initial';
      this.title_html.scrollIntoView(true);
    }
    return true
  }
}
