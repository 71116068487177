import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="decorator"
export default class extends Controller {
  connect() {
    this.setup();
  }

  setup() {
    const book = this.element;
    setTimeout(() => {
      document.querySelectorAll('a.footnote-link').forEach(function(elm) {
        let tgt = elm.href.split('#')[1];
        if (document.getElementById(tgt)) {
          elm.setAttribute('data-src', tgt);
          elm.setAttribute('data-action', 'footnote#display:capture');
        }
      });
      book.querySelectorAll('p[id], ol[id], ul[id]').forEach((elm) => {
        elm.setAttribute('data-action', 'mouseenter->reference#show mouseleave->reference#hide');
        elm.classList.add('position-relative');
      });
      return true;

    }, 5);
  }
}
