import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="eyeshade"
export default class extends Controller {
  get holder() {
    return this.element.querySelector('.warning-holder');
  }

  connect() {
    if (!document.querySelector('.editable')) return;

    if (!this.holder) {
      this.element.insertAdjacentHTML('afterbegin', '<div class="warning-holder"></div>');
    }
    this.holder.insertAdjacentHTML('afterbegin', '<button class="btn btn-light mx-2 eyeshade open" data-action="eyeshade#flip">Editor</button>');
  }

  flip() {
    this.element.classList.toggle('clean');
  }
}
