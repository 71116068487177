import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checked"
export default class extends Controller {
  connect() {
    this.signal = this.element;
    const form = this.signal.form;
    this.fields = Array.from(form.elements).filter(elm => { if (elm.matches('[type="checkbox"]') && elm != this.signal) return elm; });
    this.compare();
    document.addEventListener('click', evt => { this.compare() });
  }

  compare() {
    const checked_fields = this.fields.filter(elm => { if (elm.checked) return elm; })
    if (this.fields.length === checked_fields.length) {
      this.signal.checked = true;
      this.signal.indeterminate = false;
    } else {
      if (checked_fields.length === 0) {
        this.signal.checked = false;
        this.signal.indeterminate = false;
      } else {
        this.signal.indeterminate = true;
      }
    }
  }

  toggle(evt) {
    if (evt.target.checked) {
      this.fields.map(elm => { elm.checked = false });
    }
    if (evt.target.indeterminate || !evt.target.checked) {
      this.fields.map(elm => { elm.checked = true });
    }
    this.compare();
  }
}
